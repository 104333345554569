import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { AnimalCancelTreatmentsComponent } from '../animal-cancel-treatments.component';

import { notEmpty, listContainsString, uniqueArray, uniqueArrayFromPropertyPath } from '../../common/util';
import {
    buildGenotypeCombosFilter,
    getDateRangePredicates
} from '../../services/queries';

import { DataManagerService } from '../../services/data-manager.service';
import { QueryDef } from '../../services/query-def';
import { BaseEntityService } from '../../services/base-entity.service';
import { WebApiService } from '../../services/web-api.service';
import { MaterialService } from '../../services/material.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { ConfirmService } from '../../common/confirm';
import { SearchService } from '../../search/search.service';
import { JobService } from '../../jobs/job.service';
import { ResourceService } from '../../resources';
import { DotmaticsService } from '../../dotmatics/dotmatics.service';
import { NamingService } from '../../services/naming.service';
import { Subject } from 'rxjs';
import type { Animal, Entity, TaskOutputSet } from '@common/types';
import { DateTime } from 'luxon';


@Injectable()
export class AnimalService extends BaseEntityService {

    // state variables
    draggedAnimals: any[];
    private animalCancelTreatmentsModal: NgbModalRef = null;

    materialPoolDefaultEndStatus: any;
    taskDefaultAutoEndStatus: any;
    animalStatuses: any[];

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    // Subscribe Animal Delete
    private deleteAnimalSync = new Subject<any>();
    birthAnimalDeleted$ = this.deleteAnimalSync.asObservable();

    constructor(
        private modalService: NgbModal,
        private dataManager: DataManagerService,
        private materialService: MaterialService,
        private vocabularyService: VocabularyService,
        private webApiService: WebApiService,
        private confirmService: ConfirmService,
        private searchService: SearchService,
        private jobService: JobService,
        private resourceService: ResourceService,
        private dotmaticsService: DotmaticsService,
        private namingService: NamingService,
    ) {
        super();
        this.draggedAnimals = []; 
        this.setupDefaults();
        this.setIsDotmatics();
    }
    
    setupDefaults(): Promise<any> { 
        const promises = [];
        let promise;
        promise = this.vocabularyService.getCVDefaultEndStatus('cv_MaterialPoolStatuses').then((value) => {
            this.materialPoolDefaultEndStatus = value;            
        });
        promises.push(promise);

        promise = this.vocabularyService.getCV('cv_AnimalStatuses').then((data: any) => {
            this.animalStatuses = data;
        });

        if (this.jobService.getIsCroFlag() || !this.jobService.getIsClassicJobOnlyFlag()) {
            promise = this.vocabularyService.getCVByFieldEquals(
                'cv_TaskStatuses',
                'IsDefaultAutoEndState',
                'true',
                false
            ).then((value) => {
                this.taskDefaultAutoEndStatus = value;                
            });
            promises.push(promise);
        }

        return Promise.all(promises);
    }

    getAnimals(queryDef: QueryDef): Promise<QueryResult> {        
        let query = this.buildDefaultQuery('Animals', queryDef);

        this.ensureDefExpanded(queryDef, 'Material');
        this.ensureDefExpanded(queryDef, 'Material.MaterialPoolMaterial.MaterialPool');
        this.ensureDefExpanded(queryDef, 'Material.MaterialExternalSync');
        this.ensureDefExpanded(queryDef, 'Material.Note');
        this.ensureDefExpanded(queryDef, 'Order');
        this.ensureDefExpanded(queryDef, "AnimalComment.cv_AnimalCommentStatus");
        this.ensureDefExpanded(queryDef, "Material.MaterialPoolMaterial.MaterialPool.SocialGroupMaterial");
        this.ensureDefExpanded(queryDef, 'TaxonCharacteristicInstance.TaxonCharacteristic.TaxonCharacteristicTaxon');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.ensureCVsLoaded().then(() => {
            return this.dataManager.executeQuery(query);
        }).catch(this.dataManager.queryFailed);
    }

    fetchTasks(taskKeys: any[]): Promise<TaskOutputSet[]> {
        const expands = [
            'TaskOutput.Output'
        ];
        const query = EntityQuery.from('TaskOutputSets')
            .expand(expands.join(','))
            .where('C_TaskInstance_key', 'in', taskKeys);

        return this.dataManager.returnQueryResults(query);
    }

    fetchOutputs(outputKeys: any[]): Promise<any> {
        const query = EntityQuery.from('Outputs')
            .where('C_Output_key', 'in', outputKeys);

        return this.dataManager.returnQueryResults(query);
    }

    ensureCVsLoaded(): Promise<void[]> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_PhysicalMarkerTypes')
        ]);
    }

    ensureListViewAssociatedDataLoaded(animals: any[], visibleColumns?: string[]): Promise<void> {
        const expands = [
            'Birth.Mating',
            'Birth.BirthMaterial.Material.Animal',
            'Material.Line',
            'Material.MaterialPoolMaterial.MaterialPool'
        ];

        if (listContainsString(visibleColumns, 'Genotype')) {
            expands.push('Genotype');
        }

        if (listContainsString(visibleColumns, 'Protocol')) {
            expands.push('Material.TaskMaterial.TaskInstance.ProtocolTask.Protocol');
        }

        if (listContainsString(visibleColumns, 'Job') ||
            listContainsString(visibleColumns, 'Study')
        ) {
            expands.push('Material.JobMaterial.Job.Study');
        }

        if (listContainsString(visibleColumns, 'Job')) {
            expands.push('Material.JobMaterial.Job');
        }

        if (listContainsString(visibleColumns, 'Plate')) {
            expands.push('Material.PlateMaterial.Plate');
        }

        if (listContainsString(visibleColumns, 'Cohort')) {
            expands.push('Material.CohortMaterial.Cohort');
        }

        if (listContainsString(visibleColumns, 'Sire') ||
            listContainsString(visibleColumns, 'Dam')
        ) {
            expands.push('Birth.Mating.MaterialPool.MaterialPoolMaterial.Material.Animal');
        }
        return this.dataManager.ensureRelationships(animals, expands);
    }

    async ensureVisibleColumnsDataLoaded(animals: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(animals[0], visibleColumns);
        if (expands.includes('Genotype')) {
            await Promise.all([
                this.vocabularyService.getCV('cv_GenotypeAssays', 'SortOrder'),
                this.vocabularyService.getCV('cv_GenotypeSymbols', 'SortOrder'),
            ]);
        }
        return this.dataManager.ensureRelationships(animals, expands);
    }

    ensureBulkEditAssociatedDataLoaded(animals: any[]): Promise<void> {
        const expands = [
            "Birth.Mating",
            "Genotype",
            "Material.JobMaterial.Job",
            "Material.Line",
            "Material.MaterialPoolMaterial.MaterialPool",
            "Material.PlateMaterial.Plate",
            "TaxonCharacteristicInstance.TaxonCharacteristic"
        ];
        return this.dataManager.ensureRelationships(animals, expands);
    }


    /**
     * Filters current animals
     * Bring alive animals, with no date exit and no exitStatus
     * in the last 6 months
     */
    currentAnimalsPredicate() {
        let fromDate = DateTime.now();
        fromDate = fromDate.minus({months: 6});

        const predFromDate = Predicate.create('DateExit', '==', null);
        const predExitStatus =  Predicate.or([
            Predicate.create('cv_AnimalStatus.IsExitStatus', '==', null),
            Predicate.create('cv_AnimalStatus.IsExitStatus', '==', false)
        ]);

        return predFromDate.and(predExitStatus);
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];

        if (!filter) {
            return;
        }

        if (filter.identifier && filter.identifier !== 'null') {
            predicates.push(Predicate.create('Material.Identifier', '==', filter.identifier));
        }
        if (notEmpty(filter.identifiers)) {
            predicates.push(Predicate.create('Material.Identifier', 'in', filter.identifiers));
        }
        if (notEmpty(filter.microchipIdentifiers)) {
            predicates.push(Predicate.create(
                'Material.MicrochipIdentifier', 'in', filter.microchipIdentifiers
            ));
        }
        if (notEmpty(filter.materialKeys)) {
            predicates.push(Predicate.create('C_Material_key', 'in', filter.materialKeys));
        }
        if (notEmpty(filter.mKeys)) {
            predicates.push(Predicate.create('C_Material_key', '>=', filter.mKeys[0]));
            predicates.push(Predicate.create('C_Material_key', '<=', filter.mKeys[1]));
        }
        if (filter.animalName) {
            predicates.push(Predicate.create('AnimalName', FilterQueryOp.Contains, { value: filter.animalName }));
        }
        if (notEmpty(filter.animalNames)) {
            predicates.push(Predicate.create('AnimalName', 'in', filter.animalNames));
        }
        if (filter.animalNameFrom) {
            predicates.push(Predicate.create('AnimalNameSortable', '>=', filter.animalNameFrom));
        }
        if (filter.animalNameTo) {
            predicates.push(Predicate.create('AnimalNameSortable', '<=', filter.animalNameTo));
        }
        if (filter.shipmentID) {
            predicates.push(Predicate.create('ShipmentID', FilterQueryOp.Contains, { value: filter.shipmentID }));
        }
        if (notEmpty(filter.shipmentIDs)) {
            predicates.push(Predicate.create('ShipmentID', 'in', filter.shipmentIDs));
        }
        if (filter.vendorID) {
            predicates.push(Predicate.create('VendorID', FilterQueryOp.Contains, { value: filter.vendorID }));
        }
        if (notEmpty(filter.vendorIDs)) {
            predicates.push(Predicate.create('VendorID', 'in', filter.vendorIDs));
        }
        if (notEmpty(filter.cohorts)) {
            const cohortKeys = filter.cohorts.map((cohort: any) => {
                return cohort.C_Cohort_key;
            });
            predicates.push(Predicate.create(
                'Material.CohortMaterial', 'any',
                'C_Cohort_key', 'in', cohortKeys
            ));
        }
        if (filter.jobID) {
            predicates.push(Predicate.create(
                'Material.JobMaterial', FilterQueryOp.Any,
                'Job.JobID', FilterQueryOp.Contains, { value: filter.jobID },
            ));
        }
        if (filter.C_Study_key) {
            predicates.push(Predicate.create(
                'Material.JobMaterial', 'any',
                'Job.C_Study_key', 'eq', filter.C_Study_key
            ));
        }
        if (filter.externalIdentifier) {
            predicates.push(Predicate.create(
                'Material.ExternalIdentifier', FilterQueryOp.Contains, { value: filter.externalIdentifier },
            ));
        }
        if (filter.alternatePhysicalID) {
            predicates.push(Predicate.create(
                'AlternatePhysicalID', FilterQueryOp.Contains, { value: filter.alternatePhysicalID },
            ));
        }
        if (filter.location) {
            predicates.push(Predicate.create(
                'Material.CurrentLocationPath', FilterQueryOp.Contains, { value: filter.location },
            ));
        }
        if (filter.C_Taxon_key) {
            predicates.push(Predicate.create('Material.C_Taxon_key', 'eq', filter.C_Taxon_key));
        }
        if (notEmpty(filter.C_AnimalStatus_keys)) {
            predicates.push(
                Predicate.create('C_AnimalStatus_key', 'in', filter.C_AnimalStatus_keys)
            );
        }
        if (filter.heldFor) {
            predicates.push(Predicate.create(
                'HeldFor', 'substringof', filter.heldFor
            ));
        }
        if (filter.cITESNumber) {
            predicates.push(Predicate.create(
                'CITESNumber', 'substringof', filter.CITESNumber
            ));
        }
        if (notEmpty(filter.C_AnimalClassification_keys)) {
            predicates.push(
                Predicate.create('C_AnimalClassification_key', 'in', filter.C_AnimalClassification_keys)
            );
        }
        if (notEmpty(filter.C_AnimalMatingStatus_keys)) {
            predicates.push(
                Predicate.create('C_AnimalMatingStatus_key', 'in', filter.C_AnimalMatingStatus_keys)
            );
        }
        if (notEmpty(filter.C_BreedingStatus_keys)) {
            predicates.push(
                Predicate.create('C_BreedingStatus_key', 'in', filter.C_BreedingStatus_keys)
            );
        }
        if (filter.owner) {
            predicates.push(Predicate.create('Owner', FilterQueryOp.Contains, { value: filter.owner }));
        }
        if (filter.C_AnimalUse_key) {
            predicates.push(Predicate.create('C_AnimalUse_key', 'eq', filter.C_AnimalUse_key));
        }
        if (filter.C_IACUCProtocol_key) {
            predicates.push(
                Predicate.create('C_IACUCProtocol_key', 'eq', filter.C_IACUCProtocol_key));
        }
        if (filter.C_Sex_key) {
            predicates.push(Predicate.create('C_Sex_key', 'eq', filter.C_Sex_key));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });
            predicates.push(Predicate.create(
                'Material.C_Line_key', 'in', lineKeys
            ));
        }
        if (notEmpty(filter.stockIDs)) {
            predicates.push(Predicate.create(
                'Material.Line.StockID', 'in', filter.stockIDs
            ));
        }
        if (filter.dateBornStart || filter.dateBornEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateBorn',
                filter.dateBornStart,
                filter.dateBornEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.dateExitStart || filter.dateExitEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateExit',
                filter.dateExitStart,
                filter.dateExitEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.C_ExitReason_key) {
            predicates.push(Predicate.create('C_ExitReason_key', 'eq', filter.C_ExitReason_key));
        }
        if (filter.plateID) {
            predicates.push(Predicate.create(
                'Material.PlateMaterial', FilterQueryOp.Any, 'Plate.PlateID', FilterQueryOp.Contains, { value: filter.plateID },
            ));
        }
        if (filter.materialPoolMaterial) {
            predicates.push(Predicate.create(
                'Birth.Mating.MaterialPool.MaterialPoolMaterial', FilterQueryOp.Any,
                'Material.Animal.AnimalName', FilterQueryOp.Contains, { value: filter.materialPoolMaterial },
            ));
        }
        if (filter.matingID) {
            predicates.push(Predicate.create('Birth.Mating.MatingID', FilterQueryOp.Contains, { value: filter.matingID }));
        }
        if (notEmpty(filter.matingIDs)) {
            predicates.push(Predicate.create('Birth.Mating.MatingID', 'in', filter.matingIDs));
        }
        if (notEmpty(filter.C_Diet_keys)) {
            predicates.push(Predicate.create('C_Diet_key', 'in', filter.C_Diet_keys));
        }

        if (notEmpty(filter.C_Generation_keys)) {
            predicates.push(Predicate.create('C_Generation_key', 'in', filter.C_Generation_keys));
        }

        if (notEmpty(filter.C_MaterialOrigin_keys)) {
            predicates.push(Predicate.create(
                'Material.C_MaterialOrigin_key', 'in', filter.C_MaterialOrigin_keys
            ));
        }

        if (filter.dateWeanStart || filter.dateWeanEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'Birth.DateWean',
                filter.dateWeanStart,
                filter.dateWeanEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.dateOriginStart || filter.dateOriginEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateOrigin',
                filter.dateOriginStart,
                filter.dateOriginEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (notEmpty(filter.genotypeCombos)) {            
            if (filter.genotypeAnd) { 
                const comboarray = [];     
                for (const genotypeCombo of filter.genotypeCombos) {  
                    const combo = [genotypeCombo];
                    comboarray.push(Predicate.create('Genotype',
                        'any', buildGenotypeCombosFilter(combo)));                     
                }                 
                predicates.push(Predicate.and(comboarray));              

            } else {                             
                predicates.push(Predicate.create('Genotype',
                    'any', buildGenotypeCombosFilter(filter.genotypeCombos)));
            } 
           
        }

        if (notEmpty(filter.protocols)) {
            const protocolKeys = filter.protocols.map((protocol: any) => {
                return protocol.ProtocolKey;
            });
            predicates.push(Predicate.create(
                'Material.TaskMaterial', 'any',
                'TaskInstance.ProtocolTask.C_Protocol_key', 'in', protocolKeys
            ));
        }

        if (notEmpty(filter.orders)) {
            const orderKeys = filter.orders.map((order: any) => {
                return order.OrderKey;
            });
            predicates.push(Predicate.create(
                Predicate.create('C_Order_key', 'in', orderKeys)
            ));
        }

        if (notEmpty(filter.constructs)) {
            const constructKeys = filter.constructs.map((construct: any) => {
                return construct.ConstructKey;
            });

            const subPredicate = Predicate.create(
                'Material.Sample.SampleConstruct', 'any',
                'C_Construct_key', 'in', constructKeys
            );

            predicates.push(Predicate.create(
                'Birth.Mating.MaterialPool.MaterialPoolMaterial', 'any',
                subPredicate
            ));
        }

        if (filter.createdBy) {
            predicates.push(Predicate.create('CreatedBy', FilterQueryOp.Contains, { value: filter.createdBy }));
        }

        if (filter.dateCreatedStart || filter.dateCreatedEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateCreated',
                filter.dateCreatedStart,
                filter.dateCreatedEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (notEmpty(filter.housingUnits)) {
            const housingKeys = filter.housingUnits.map((pool: any) => {
                return pool.C_MaterialPool_key;
            });
            // Only search pools with no DateOut value
            const subPredicate = Predicate.and([
                Predicate.create('DateOut', 'eq', null),
                Predicate.create('C_MaterialPool_key', 'in', housingKeys)
            ]);

            predicates.push(Predicate.create(
                'Material.MaterialPoolMaterial', 'any',
                subPredicate
            ));
        }

        if (notEmpty(filter.birthIds)) {
            const birthKeys = filter.birthIds.map((birth: any) => {
                return birth.C_Birth_key;
            });

            predicates.push(Predicate.create(
                Predicate.create('C_Birth_key', 'in', birthKeys)
            ));
        }

        if (filter.physicalMarker) {
            predicates.push(Predicate.create(
                'PhysicalMarker', FilterQueryOp.Contains, { value: filter.physicalMarker },
            ));
        }

        if (filter.taxonCharacteristics) {
            for (const characteristicKey in filter.taxonCharacteristics) {
                if (filter.taxonCharacteristics.hasOwnProperty(characteristicKey)) {
                    const instanceValue = filter.taxonCharacteristics[characteristicKey];
                    if (instanceValue && instanceValue.trim().length > 0) {
                        const characteristicNameMatch = Predicate.create("C_TaxonCharacteristic_key", "eq", characteristicKey);
                        const characteristicValueMatch = Predicate.create("CharacteristicValue", "contains", instanceValue);

                        const characteristicMatch = Predicate.create("TaxonCharacteristicInstance", "any", Predicate.and(characteristicNameMatch, characteristicValueMatch));
                        predicates.push(characteristicMatch);
                    }
                }
            }
        }

        // handle workspace filters
        if ('job-filter' in filter) {
            predicates.push(Predicate.create(
                'Material.JobMaterial', 'any', 'Job.C_Job_key', 'in', filter['job-filter']
            ));
        }

        return predicates;
    }

    /**
     * create an Animal entity
     */
    create(): any {
        return this.dataManager.createEntity('Animal');
    }

    /**
     * @deprecated
     * An unused method may need to be removed
     */
    getAnimalByName(animalName: string): Promise<any> {
        const query = EntityQuery.from('Animals')
            .expand('cv_Sex, Material.Line')
            .where('AnimalName', '==', animalName);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getAnimal(materialKey: number, expands?: string[]): Promise<any> {
        if (!expands) {
            expands = [];
        }

        // TODO (kevin.stone): move these dependencies to caller functions
        //   in order to speed up pages like Clinical detail,
        //   as not every page needs sample sources and genotypes, etc
        this.ensureExpanded(expands, 'cv_Sex');
        this.ensureExpanded(expands, 'Genotype.cv_GenotypeAssay');
        this.ensureExpanded(expands, 'Genotype.cv_GenotypeSymbol');
        this.ensureExpanded(expands, 'Material.Line');
        this.ensureExpanded(expands, 'Material.MaterialSourceMaterial');
        this.ensureExpanded(expands, 'Material.StoredFileMap');
        this.ensureExpanded(expands, 'TaxonCharacteristicInstance.TaxonCharacteristic');

        const query = EntityQuery.from('Animals')
            .expand(expands.join(','))
            .where('C_Material_key', '==', materialKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getAnimalClinical(materialKey: number, expands?: string[]): Promise<any> {
        if (!expands) {
            expands = [];
        }      

        const query = EntityQuery.from('Animals')
            .expand(expands.join(','))
            .where('C_Material_key', '==', materialKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    /**
     * @deprecated
     * An unused method may need to be removed
     */
    getFilteredAnimals(
        filterText: string,
        maxResultsCount: number
    ): Promise<any[]> {

        const predicate = this.buildAnimalNameSearchPredicate(filterText);

        let query = EntityQuery.from('Animals')
            .where(predicate)
            .orderBy('AnimalNameSortable');

        if (maxResultsCount) {
            query = query.top(maxResultsCount);
        }

        return this.dataManager.returnQueryResults(query);
    }

    getFilteredCurrentAnimalsBySex(
        filterText: string,
        sex?: string,
        maxResultsCount?: number
    ): Promise<any[]> {

        const predicates = [
            this.currentAnimalsPredicate()
        ];     

        if (filterText) {            
            predicates.push(this.buildAnimalNameSearchPredicate(filterText));
        }

        if (sex) {
            predicates.push(Predicate.create('cv_Sex.Sex', "eq", sex));
        } else {
            predicates.push(Predicate.create('cv_Sex.Sex', "!=", 'Male'));
            predicates.push(Predicate.create('cv_Sex.Sex', "!=", 'Female'));
        }

        let query = EntityQuery.from('Animals')
            .where(Predicate.and(predicates))
            .expand('Material')
            .orderBy('AnimalNameSortable');

        if (maxResultsCount) {
            query = query.top(maxResultsCount);
        }

        return this.dataManager.returnQueryResults(query);
    }

    ensureTasksLoaded(animals: any[]): Promise<any[]> {
        const expands = [
            'Material.TaskMaterial.TaskInstance.ProtocolInstance',
            'Material.TaskMaterial.TaskInstance.ProtocolTask',
        ];
        return this.dataManager.ensureRelationships(animals, expands);
    }

    getTaskMaterials(materialKey: number, extraExpands?: string[]): Promise<any[]> {
        const predicates = [
            new Predicate('TaskMaterial', 'any', 'C_Material_key', '==', materialKey),
            new Predicate('WorkflowTask.cv_TaskType.TaskType', 'eq', '"Animal"')
        ];

        const query = EntityQuery.from('TaskInstances')
            .where(Predicate.and(predicates))
            .orderBy('ProtocolTask.SortOrder');

        let expandClauses = [
            'WorkflowTask',
            'ProtocolTask.Protocol',
            'ProtocolInstance.Protocol',
            'TaskInput.Input',
            'TaskMaterial'
        ];

        if (notEmpty(extraExpands)) {
            expandClauses = expandClauses.concat(extraExpands);
        }

        let tasks: any[] = [];
        return this.dataManager.returnQueryResults(query).then((results) => {
            tasks = results;
            const promises: Promise<any>[] = [
                this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
                this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
                this.vocabularyService.ensureCVLoaded('cv_DataTypes'),
                this.vocabularyService.ensureCVLoaded('cv_TaskTypes')
            ];

            return Promise.all(promises);
        }).then(() => {
            return this.dataManager.ensureRelationships(tasks, expandClauses);
        }).then(() => {
            return tasks;
        });
    }

    buildAnimalNameSearchPredicate(filterText: string): Predicate { 
        
        return Predicate.or([            
            Predicate.create('AnimalName', FilterQueryOp.Contains, { value: filterText }),
            Predicate.create('Material.MicrochipIdentifier', "eq", filterText)
        ]);
    }

    /**
     * @deprecated
     * An unused method may need to be removed
     */
    getGenotypes(materialKey: number): Promise<string> {

        // TODO: Get from cache
        const query = EntityQuery.from('Genotypes')
            .expand('cv_GenotypeSymbol, cv_GenotypeAssay')
            .where('C_Material_key', '==', materialKey);

        return this.dataManager.executeQuery(query).then((data) => {
            const genotypes: string[] = [];
            for (const result of data.results) {
                const genotype = <any> result;
                if (genotype.cv_GenotypeAssay) {
                    const assay = genotype.cv_GenotypeAssay.GenotypeAssay;
                    const symbol = genotype.cv_GenotypeSymbol.GenotypeSymbol;
                    genotypes.push(assay + ' : ' + symbol);
                }
            }
            return genotypes.join(',');
        })
            .catch(this.dataManager.queryFailed) as Promise<string>;
    }

    getTaxonCharacteristics(materialKey: number): Promise<any[]> {
        const query = EntityQuery.from('TaxonCharacteristicInstances')
            .expand('TaxonCharacteristic.cv_DataType')
            .where('C_Material_key', '==', materialKey)
            .orderBy('TaxonCharacteristic.SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    getMaterialPoolHistory(materialKey: number): Promise<any[]> {

        const expands = [
            'MaterialPool.MaterialLocation.LocationPosition',
            'MaterialPool.cv_MaterialPoolType',
            'MaterialPool.SocialGroupMaterial.Material.Animal',
        ];

        const query = EntityQuery.from("MaterialPoolMaterials")
            .expand(expands.join(','))
            .where('C_Material_key', '==', materialKey)
            .noTracking(true);

        return this.vocabularyService.ensureCVLoaded('cv_MaterialPoolTypes').then(() => {
            return this.dataManager.returnQueryResults(query);
        });
    }

    createTaxonCharacteristics(animal: any): Promise<any[]> {
        const taxonKey: number = animal.Material.C_Taxon_key;
        return this.getActiveCharacteristics(taxonKey).then((characteristics) => {
            return this.createTaxonCharacteristicsWithInitialValues(animal, characteristics);
        });
    }

    createTaxonCharacteristicsWithInitialValues(animal: any, characteristics: any): any[] {
        const newCharacteristics: any[] = [];
        for (const characteristic of characteristics) {
            const newCharacteristic = this.dataManager.createEntity(
                'TaxonCharacteristicInstance',
                {
                    C_TaxonCharacteristic_key: characteristic.C_TaxonCharacteristic_key,
                    C_Material_key: animal.Material.C_Material_key,
                    DateCreated: Date.now(),
                    CharacteristicValue: characteristic.CharacteristicValue,
                    CharacteristicName: characteristic.CharacteristicName,
                    Description: characteristic.Description
                }
            );
            newCharacteristics.push(newCharacteristic);
        }
        return newCharacteristics;
    }

    getActiveCharacteristics(taxonKey: number): Promise<any[]> {
        const predicates: Predicate[] = [];
        predicates.push(Predicate.create('IsActive', '==', true));
        predicates.push(Predicate.create('TaxonCharacteristicTaxon', 'any', 'C_Taxon_key', '==', taxonKey));

        const query = EntityQuery.from('TaxonCharacteristics')
            .expand('cv_DataType, TaxonCharacteristicTaxon')
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    bulkDeleteAnimals(animals: any[]): Promise<any> {
        return this.webApiService.postApi('api/bulkdata/animalswithassociateddata', {
            materialKeys: uniqueArrayFromPropertyPath(animals, 'C_Material_key')
        });
    }

    deleteAnimal(animal: any) {  
        if (animal.TaxonCharacteristicInstance) {
            while (animal.TaxonCharacteristicInstance.length > 0) {
                this.dataManager.deleteEntity(animal.TaxonCharacteristicInstance[0]);
            }
        }

        if (animal.Event) {
            while (animal.Event.length > 0) {
                this.dataManager.deleteEntity(animal.Event[0]);
            }
        }

        if (animal.Genotype) {
            while (animal.Genotype.length > 0) {
                this.dataManager.deleteEntity(animal.Genotype[0]);
            }
        }

        if (animal.AnimalComment) {
            while (animal.AnimalComment.length > 0) {
                this.dataManager.deleteEntity(animal.AnimalComment[0]);
            }
        }

        // this.deleteAnimalSync.next(animal);
        this.dataManager.deleteEntity(animal);
    }

    async validateAnimalNamingField(animal: any): Promise<string> {
        const animalPrefixField = await this.getAnimalPrefixField();
        if (animalPrefixField === 'Text') {
            return '';
        } else if (animalPrefixField === 'Line') {
            return animal.Material.C_Line_key ? '' : 'Line';
        }
    }

    getAnimalPlaceholders(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AnimalPlaceholders', queryDef);

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildAnimalPlaceholdersdPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    private buildAnimalPlaceholdersdPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (filter.name) {
            predicates.push(Predicate.create('Name', FilterQueryOp.Contains, { value: filter.name }));
        }

        return predicates;
    }

    /**
     * @deprecated
     * An unused method may need to be removed
     */
    deleteTaxonCharacteristic(taxonCharacteristic: any) {
        this.dataManager.deleteEntity(taxonCharacteristic);
    }

    /**
     * In some cases we need to update DateExit, History and Housing Status after an animal
     * status change. This function handles that logic.
     * @param animal the animal whose status has changed
     */
    statusBulkChangePostProcess(animals: any[], animalStatusKey: any): Promise<any> {        
        const promises: any[] = [];
        if (animalStatusKey === null || animalStatusKey === undefined) {
            return;
        }

        const animalStatus: any = this.animalStatuses.find((item) => {
            return item.C_AnimalStatus_key.toString() === animalStatusKey.toString();
        });

        const dateExit = new Date();

        return this.dataManager.ensureRelationships(animals, ['cv_AnimalStatus']).then(() => {
            for (const animal of animals) {
                animal.PreviousIsExitStatus = animal.cv_AnimalStatus ? animal.cv_AnimalStatus.IsExitStatus : false;
                animal.C_AnimalStatus_key = animalStatus.C_AnimalStatus_key;
                if (animalStatus.IsExitStatus) {
                    if (animal.DateExit === null || animal.DateExit === undefined) {
                        animal.HousingRemoved = true;
                        animal.DateExit = dateExit;
                        if (animal.History) {
                            for (const pool of animal.History) {
                                if (pool.DateOut === null || pool.DateOut === undefined) {
                                    pool.DateOut = dateExit;
                                }
                            }
                        }
                    }
                } else {
                    animal.DateExit = null;
                }
            }

            if (animalStatus.IsExitStatus) {
                const p = this.setupDefaults()
                    .then(() => {
                        return this.bulkUpdateWorkflowTask(animals, animalStatus);
                    }).then(() => {
                        return this.bulkExitAnimals(animals, dateExit);
                    }).then(() => {
                        for (const animal of animals) {
                            if (animal.HousingRemoved) {
                                this.promptUserToCloseTasks(animal);
                            }
                        }
                    });
                promises.push(p);
            }
            return Promise.all(promises);
        });
    }

    /**
     * In some cases we need to update DateExit, History and Housing Status after an animal
     * status change. This function handles that logic.
     * @param animal the animal whose status has changed
     */
    statusChangePostProcess(animal: any) {
        this.dataManager.ensureRelationships([animal], ['cv_AnimalStatus']).then(() => { 
            if (!animal.cv_AnimalStatus) {
                return;
            }
        
            if (animal.cv_AnimalStatus.IsExitStatus) {
                this.setupDefaults()
                    .then(() => {
                        return this.bulkUpdateWorkflowTask([animal]);
                    }).then(() => {
                        if (animal.DateExit === null || animal.DateExit === undefined) {
                            this.exitAnimal(animal);
                            this.promptUserToCloseTasks(animal);
                        }
                    });
            } else {
                animal.DateExit = null;
            }
        });
    }

    exitAnimal(animal: any): Promise<void> {
        const dateExit = new Date();
        
        animal.DateExit = dateExit;
        if (animal.History) {
            for (const pool of animal.History) {
                if (pool.DateOut === null || pool.DateOut === undefined) {
                    pool.DateOut = dateExit;
                }
            }
        }

        const query = EntityQuery.from('MaterialPoolMaterials')
            .where('C_Material_key', '==', animal.C_Material_key);

        return this.dataManager.executeQuery(query).then((data) => {
            const results = data.results as any[]; 
            if (results.length > 0) {
                for (const materialPoolMaterial of results) {
                    if (materialPoolMaterial.DateOut === null || materialPoolMaterial.DateOut === undefined) {
                        materialPoolMaterial.DateOut = dateExit;
                        if (materialPoolMaterial.MaterialPool) {
                            this.setHousingEndState(materialPoolMaterial.MaterialPool.MaterialPoolMaterial, materialPoolMaterial.MaterialPool);
                        }
                    }
                }
            } else {
                // check if has a new housing not saved yet
                if (animal.Material.MaterialPoolMaterial.length === 1 && animal.Material.MaterialPoolMaterial[0].C_MaterialPool_key < 0) {
                    animal.Material.MaterialPoolMaterial[0].DateOut = dateExit;
                    this.setHousingEndState(animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolMaterial, animal.Material.MaterialPoolMaterial[0].MaterialPool);
                }
            }     
        }).catch(this.dataManager.queryFailed);        
    }

    bulkUpdateWorkflowTask(animals: any[], newAnimalStatus: any = null): Promise<any> {       
        let p: Promise<void>;
        const animalsToExit: any[] = [];
        const animalsToExitNames: string[] = [];
        const animalsToExitMaterialKeys: number[] = [];

        if (animals.length === 1 && !newAnimalStatus) {
            const animal = animals[0];
            p = this.searchService.getIsExitStatus(animal.C_Material_key).then((result: any) => {
                if (!result.data) {
                    animalsToExit.push(animal);
                    animalsToExitNames.push(animal.AnimalName);
                    animalsToExitMaterialKeys.push(animal.C_Material_key);
                }
            });
        } else {
            if (newAnimalStatus && newAnimalStatus.IsExitStatus) {
                for (const animal of animals) {
                    if (!animal.PreviousIsExitStatus) {
                        animalsToExit.push(animal);
                        animalsToExitNames.push(animal.AnimalName);
                        animalsToExitMaterialKeys.push(animal.C_Material_key);
                    }
                }
            }
            p = Promise.resolve();
        }

        return p.then(() => { 
            if (animalsToExit.length > 0) {

                const predicates: Predicate[] = [
                    Predicate.create('TaskMaterial', 'any', 'C_Material_key', 'in', animalsToExitMaterialKeys),
                    Predicate.create('WorkflowTask.AutomaticallyEndTask', '==', true),
                    Predicate.create('IsWorkflowLocked', '!=', true),
                    Predicate.or([
                        Predicate.create('C_TaskStatus_key', '==', null),
                        Predicate.create('cv_TaskStatus.IsEndState', '!=', true)
                    ]),
                    Predicate.create('C_GroupTaskInstance_key', '!=', null)
                ];

                const query = EntityQuery.from('TaskInstances')
                    .expand(['cv_TaskStatus', 'TaskMaterial', 'WorkflowTask'])
                    .where(Predicate.and(predicates));

                return this.dataManager.executeQuery(query).then((data) => {
                    const taskInstances: any[] = data.results;
                    if (this.taskDefaultAutoEndStatus && taskInstances && taskInstances.length > 0) {
                        const tooltip = "This value, which is setup in your Vocabularies facet, is an end state task status that will be automatically applied to all remaining applicable tasks which the animals are assigned.";
                        const message: string = "Animal(s): " + animalsToExitNames.join(', ') + " have been set to an end state status. Would you like to apply the <span title = '"
                            + tooltip + "'><strong>Default Automatic End State</strong> <i class='fa fa-info-circle text-info' aria-hidden='true'></i></span> to all applicable workflow tasks for these animals?";

                        this.confirmService.confirm(
                            {
                                title: "Confirm default automatic end state",
                                message,
                                yesButtonText: "Yes",
                                noButtonText: "No",
                                isHtml: true
                            }
                        ).then(
                            () => {
                                // Yes
                                for (const taskInstance of taskInstances) {                                    
                                    this.dataManager.ensureRelationships([taskInstance], ['TaskOutputSet']).then(() => {
                                        return this.resourceService.getCurrentUserResource();
                                    }).then((resource: any) => {
                                        const currentResourceKey = resource ? resource.C_Resource_key : null;

                                        taskInstance.DateComplete = new Date();
                                        taskInstance.C_CompletedBy_key = currentResourceKey;

                                        const taskOutputSets = uniqueArrayFromPropertyPath(taskInstance, 'TaskOutputSet');

                                        for (const tos of taskOutputSets) {
                                            tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : taskInstance.DateComplete;
                                            tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : taskInstance.C_CompletedBy_key;
                                        }

                                        taskInstance.C_TaskStatus_key = this.taskDefaultAutoEndStatus.C_TaskStatus_key;

                                        if (this.isDotmatics && taskInstance.C_TaskInstance_key) {
                                            this.syncDotmaticsSample(taskInstance);
                                        }                                        
                                    });
                                }
                            },
                            () => {
                                // No
                            }
                        );
                    }
                }).catch(this.dataManager.queryFailed);
            }
        });
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }

    syncDotmaticsSample(task: any) {
        const outputSamples = this.dotmaticsService.filterOutputSamples(task);
        if (outputSamples.length === 1) {
            if (!outputSamples[0].Material.ExternalIdentifier && !task.dtxSynchronized) {
                // Post sample
                this.dotmaticsService.postDotmaticsSample(outputSamples[0].C_Material_key);
                task.dtxSynchronized = true;
            } else {
                // Update animal
                this.dotmaticsService.updateDotmaticsSample(outputSamples[0].C_Material_key);
            }
        }
    }
   
    bulkExitAnimals(animals: any[], dateExit: any): Promise<void> {
        let materialKeys: number[] = animals.map((animal) => {
            return animal.C_Material_key;
        });
        materialKeys = uniqueArray(materialKeys);

        const query = EntityQuery.from('MaterialPoolMaterials')
            .where('C_Material_key', 'in', materialKeys);

        return this.dataManager.ensureRelationships(animals, ['Material.MaterialPoolMaterial.MaterialPool']).then(() => {
            if (materialKeys.length > 0) {
                return this.dataManager.executeQuery(query);
            } else {
                return Promise.resolve(null);
            }
        }).then((data) => {
            const results = data && data.results ? data.results as any[] : [];
            let materialPoolIDs: any[] = [];
            let materialPoolKeys: any[] = [];
            let newHousingNotSaved = false;

            if (results.length > 0) {
                for (const materialPoolMaterial of results) {
                    if (materialPoolMaterial.DateOut === null || materialPoolMaterial.DateOut === undefined) {
                        materialPoolMaterial.DateOut = dateExit;
                        materialPoolMaterial.Removed = true;
                        if (materialPoolMaterial.MaterialPool && materialPoolMaterial.MaterialPool.C_MaterialPoolStatus_key !== this.materialPoolDefaultEndStatus.C_MaterialPoolStatus_key) {

                            const currentMaterialPoolMaterials = materialPoolMaterial.MaterialPool.MaterialPoolMaterial.filter((item: any) => {
                                return item.DateOut === null;
                            });
                            if (currentMaterialPoolMaterials.length === 0) {
                                if (materialPoolMaterial.MaterialPool.MaterialPoolID) {
                                    materialPoolIDs.push(materialPoolMaterial.MaterialPool.MaterialPoolID);
                                }
                                materialPoolKeys.push(materialPoolMaterial.MaterialPool.C_MaterialPool_key);
                            }
                        }
                    }
                }
            } else {
                // check if animal has a new housing not saved yet
                for (const animal of animals) {
                    if (animal.Material.MaterialPoolMaterial.length === 1 && animal.Material.MaterialPoolMaterial[0].C_MaterialPool_key < 0) {
                        animal.Material.MaterialPoolMaterial[0].DateOut = dateExit;
                        animal.Material.MaterialPoolMaterial[0].Removed = true;
                        if (animal.Material.MaterialPoolMaterial[0].MaterialPool.C_MaterialPoolStatus_key !== this.materialPoolDefaultEndStatus.C_MaterialPoolStatus_key) {
                            if (animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolID) {
                                materialPoolIDs.push(animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolID);
                            }
                            materialPoolKeys.push(animal.Material.MaterialPoolMaterial[0].C_MaterialPool_key);
                        }
                        results.push(animal.Material.MaterialPoolMaterial[0]);
                    }                   
                }
                if (results.length > 0) {
                    newHousingNotSaved = true;
                }
            }
            materialPoolIDs = uniqueArray(materialPoolIDs);
            materialPoolKeys = uniqueArray(materialPoolKeys);
            if (materialPoolKeys.length > 0 || newHousingNotSaved) {
                const ids = materialPoolIDs.join(', ');
                let message;
                if (materialPoolKeys.length === 1) {
                    if (materialPoolIDs.length === 1) {
                        message = "All materials have been removed from Housing unit " + materialPoolIDs[0] + ". Set the Housing unit to the default end state?";
                    } else {
                        message = "All materials have been removed from Housing unit. Set the Housing unit to the default end state?";
                    }
                } else if (materialPoolIDs.length === 0) {
                    message = "All materials have been removed from Housing units. Set the Housing units to the default end state?";                     
                } else {                        
                    message = "All materials have been removed from Housing units " + ids + ". Set the Housing units to the default end state?";  
                } 

                this.confirmService.confirm(
                    {
                        title: "Confirm default end state",
                        message,
                        yesButtonText: "Yes",
                        noButtonText: "No"
                    }
                ).then(
                    () => {
                        // Yes
                        for (const materialPoolMaterial of results) {
                            if (materialPoolMaterial.Removed) {
                                this.setHousingEndState(materialPoolMaterial.MaterialPool.MaterialPoolMaterial, materialPoolMaterial.MaterialPool, false);
                            }
                        }
                    },
                    () => {
                        // No
                    });
            }
        }).catch(this.dataManager.queryFailed);
    }

    setHousingEndState(materialPoolMaterials: any[], materialPool: any, confirm = true) {
        if (this.materialPoolDefaultEndStatus && materialPool.C_MaterialPoolStatus_key !== this.materialPoolDefaultEndStatus.C_MaterialPoolStatus_key)  {            
            const currentMaterialPoolMaterials = materialPoolMaterials.filter((materialPoolMaterial) => {
                return materialPoolMaterial.DateOut === null;
            });
            let message: string;
            if (materialPool.MaterialPoolID) {
                message = "All materials have been removed from Housing unit " + materialPool.MaterialPoolID + ". Set the Housing unit to the default end state?";
            } else {
                message = "All materials have been removed from Housing unit. Set the Housing unit to the default end state?";
            }
            if (currentMaterialPoolMaterials.length === 0) {
                if (confirm) {
                    this.confirmService.confirm(
                        {
                            title: "Confirm default end state",
                            message,
                            yesButtonText: "Yes",
                            noButtonText: "No"
                        }
                    ).then(
                        () => {
                            // Yes
                            materialPoolMaterials[0].MaterialPool.C_MaterialPoolStatus_key = this.materialPoolDefaultEndStatus.C_MaterialPoolStatus_key;
                        },
                        () => {
                            // No
                        });
                } else {
                    materialPoolMaterials[0].MaterialPool.C_MaterialPoolStatus_key = this.materialPoolDefaultEndStatus.C_MaterialPoolStatus_key;
                }
            }
        }
    }

    promptUserToCloseTasks(animal: any): Promise<any> {
        const animalHealthRecord = animal.AnimalHealthRecord;
        let promise = Promise.resolve(animalHealthRecord);
        if (!animalHealthRecord) {
            // need to query this if not loaded
            const expands = [
                'TaskAnimalHealthRecord',
                'TaskAnimalHealthRecord.TaskInstance',
                'TaskAnimalHealthRecord.TaskInstance.cv_TaskStatus'
            ];
            const query = EntityQuery.from('AnimalHealthRecords')
                .expand(expands.join(','))
                .where('C_Material_key', '==', animal.C_Material_key);
            promise = this.dataManager.returnSingleQueryResult(query);
        }

        return promise.then((healthRecord: any) => {
            if (!healthRecord) {
                return;
            }

            const pendingTreatmentPlans = healthRecord.TaskAnimalHealthRecord.filter(
                (currRecord: any) => {
                    const status = currRecord.TaskInstance.cv_TaskStatus;
                    return !status || !status.IsEndState;
                }
            );

            if (pendingTreatmentPlans.length) {
                // if a modal is not yet open we need to open a new one, otherwise
                // we just add to the treatment plans in the existing modal
                if (this.animalCancelTreatmentsModal === null) {
                    this.animalCancelTreatmentsModal = this.modalService.open(
                        AnimalCancelTreatmentsComponent,
                        { size: 'lg' });
                    const modalComponent: AnimalCancelTreatmentsComponent =
                        this.animalCancelTreatmentsModal.componentInstance;
                    modalComponent.treatmentPlans = pendingTreatmentPlans;

                    this.animalCancelTreatmentsModal.result.then(() => {
                        this.animalCancelTreatmentsModal = null;
                    }).catch(() => {
                        this.animalCancelTreatmentsModal = null;
                    });
                } else {
                    const modalComponent: AnimalCancelTreatmentsComponent =
                        this.animalCancelTreatmentsModal.componentInstance;
                    for (const currPlan of pendingTreatmentPlans) {
                        modalComponent.treatmentPlans.push(currPlan);
                    }
                }
            }
        });
    }

    /**
     * Cancel a newly added animal record
     * @param animal
     */
    cancelAnimal(animal: any) {
        if (!animal) {
            return;
        }
        if (animal.C_Material_key > 0) {
            this._cancelAnimalEdits(animal);
        } else {
            this._cancelNewAnimal(animal);
        }
    }

    getPedigreeData(rootMaterialKey: number, generationCount: number): Promise<any[]> {
        const apiUrl = 'api/reports/getPedigreeData/' +
            rootMaterialKey +
            '/' +
            generationCount;

        return this.webApiService.callApi(apiUrl).then((data) => {
            return data.data;
        });
    }

    private _cancelNewAnimal(animal: any) {
        try {
            const material = animal.Material;
            this._cancelAnimalEdits(animal);
            this.deleteAnimal(animal);
            this.materialService.deleteMaterial(material);
        } catch (error) {
            console.error('Error cancelling animal add: ' + error);
        }
    }

    private _cancelAnimalEdits(animal: Entity<Animal>) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(animal);
        this.dataManager.rejectChangesToEntityByFilter(
            'Event', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'AnimalComment', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );

        // Get task materials related to animal to reject related resource entities
        const taskMaterials = animal.Material?.TaskMaterial ?? [];
        for (const taskMaterial of taskMaterials) {
            const [resource] = this.dataManager.rejectChangesToEntityByFilter(
                'Resource', (item: any) => {
                    return item.C_Resource_key === taskMaterial.TaskInstance?.C_AssignedTo_key;
                }
            );            
            if (resource) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'ResourceGroupMember', (item: any) => {
                        return item.C_ParentResource_key === resource.C_Resource_key;
                    }
                );
            }
            this.dataManager.rejectChangesToEntityByFilter(
                'TaskInput', (item: any) => {
                    return item.C_TaskInstance_key === taskMaterial.C_TaskInstance_key;
                }
            );
            this.dataManager.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskMaterial.C_TaskInstance_key;
                }
            );
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'TaskMaterial', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPoolMaterial', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'JobMaterial', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'TaxonCharacteristicInstance', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );
        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_Material_key === animal.C_Material_key;
            }
        );
        // also reject files associated with each fileMap
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }
    }

    getAnimalPrefixField(): Promise<string> {
        return this.namingService.getNameFormat('Animal').then((animalNameFormat: any) => {
            let prefixField = '';
            if (animalNameFormat && animalNameFormat.cv_AnimalPrefixField) {
                prefixField = animalNameFormat.cv_AnimalPrefixField.AnimalPrefixField;
            }

            return Promise.resolve(prefixField);
        });
    }

    autoGenerateAnimalName(animal: any): Promise<string> {
        const url = 'api/namegenerator/generateAnimalName';
        const request = {
            C_Material_key: animal.C_Material_key,
            C_Line_key: animal.Material.C_Line_key,
        };

        return this.webApiService.postApi(url, request).then((response: any) => {
            return response.data;
        });
    }
}
